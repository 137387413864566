@keyframes home-scale-button-animation {
	0% {
		width: 3vw;
		height: 3vw;
	}
	50% {
		width: 4vw;
		height: 4vw;
	}
	100% {
		width: 3vw;
		height: 3vw;
	}
}

.home .content {
	background: #eeeeee;
	color: white;
	height: 100%;
	width: 100%;
	padding-bottom: 10vw;
	overflow-y: scroll;
	overflow-x: hidden;
}

.home .list {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 3vw;
	padding: 3vw;
	overflow-y: scroll;
}

.home .add-proj-btn {
	margin: auto;
	animation-name: home-scale-button-animation;
	animation-duration: 3s;
	animation-iteration-count: infinite;
}

.home .proj-item {
	display: flex;
	color: gray;
}

.home .thumb {
	height: 13vw;
}

.home .proj-item-btns {
	display: flex;
	align-items: center;
	column-gap: 1vw;
	padding: 1vw;
}

.home .proj-item-title {
	margin-left: 1vw;
}

.home .proj-arrow {
	color: gray;
	height: 20vw;
	padding-top: 50%;
}

.home-modal .form-control {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-bottom: 0.75rem;
}

.home-modal .error {
	margin-top: 1rem;
	color: red;
}

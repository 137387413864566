.sign-in .form-wrapper {
	display: grid;
	width: 100%;
	position: fixed;
}

.sign-in .card {
	margin-right: auto;
	margin-left: auto;
	margin-top: 15vh;
}

.sign-in .form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 1vw;
}

.sign-in .button {
	margin-top: 1vw;
}

.sign-in .error {
	color: red;
}

.sign-in .logo {
	height: 3vw;
}


:root {
	--transition: all 0.3s linear;
}

*,
::after,
::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.viewer {
	position: fixed;
	top: 0%;
	left: 35%;
	width: 65%;
	height: 100%;
}

.close-btn {
	position: fixed;
	top: 1rem;
	right: 2rem;
	font-size: 2rem;
	background: transparent;
	border-color: transparent;
	color: red;
	transition: var(--transition);
}

.close-btn:hover {
	color: darkred;
	transform: rotate(360deg);
}

.save-btn {
	position: fixed;
	top: 1rem;
	right: 5rem;
	font-size: 2rem;
	background: transparent;
	border-color: transparent;
	color: green;
	transition: var(--transition);
}

.save-btn:hover {
	color: darkgreen;
	transform: scale(1.2);
}

.save-btn:active {
	color: darkgreen;
	transform: scale(0.9);
}

.informer {
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	color: black;
}

.loading-spinner {
	position: fixed;
	top: 3%;
	left: 37%;
}

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css");

@font-face {
	font-family: "tissuelabs";
	src: url("./shared/framework/ui/assets/fonts/QUICKSAND-REGULAR.TTF");
}

@font-face {
	font-family: "tissuelabs-semibold";
	src: url("./shared/framework/ui/assets/fonts/QUICKSAND-SEMIBOLD.TTF");
}

@font-face {
	font-family: "tissuelabs-bold";
	src: url("./shared/framework/ui/assets/fonts/QUICKSAND-BOLD.TTF");
}

html {
	font-size: 1.25vw;
	background: #eeeeee;
}

p {
	font-family: "tissuelabs";
}

h1 {
	font-family: "tissuelabs";
}

label {
	font-family: "tissuelabs";
}

input {
	font-family: "tissuelabs";
}

div {
	font-family: "tissuelabs";
	color: gray;
}

.btn {
	font-family: "tissuelabs";
	font-size: 1.25vw;
	padding: 0.5vw 1vw;
}

.form-control {
	font-size: 1.25vw;
}

.btn-light {
	color: gray;
}

.btn-light:hover {
	color: gray;
}

.btn-light:focus {
	color: gray;
}

.btn-light:not(:disabled):not(.disabled) {
	color: gray;
}

.btn-outline-light:not(:disabled):not(.disabled).active {
	color: gray;
}

.btn-outline-light:hover {
	color: gray;
}

.btn-secondary {
	background-color: gray;
	border-color: gray;
	color: white;
}

.btn-secondary:not(:disabled):not(.disabled).active {
	background-color: #ff80ae;
	border-color: #ff80ae;
	color: white;
}

.btn-secondary:hover {
	background-color: #ff80ae;
	border-color: #ff80ae;
	color: white;
}

.btn-danger {
	background-color: gray;
	border-color: gray;
}

.btn-success {
	background-color: gray;
	border-color: gray;
}

.btn-info {
	background-color: #ff80ae;
	border-color: #ff80ae;
	color: white;
}

.btn-info:active {
	background-color: #ffaac9 !important;
	border-color: #ffaac9 !important;
	color: white;
}

.btn-info:focus {
	background-color: #ffaac9;
	border-color: #ffaac9;
	color: white;
}

.btn-info:hover {
	background-color: gray;
	border-color: white;
	color: white;
}

.navbar {
	background: #ff80ae;
	font-size: 1.25vw;
}

.nav-link {
	color: white !important;
	font-family: "tissuelabs-semibold";
	margin-left: 1.2vw;
	font-weight: 200;
}

.nav-link:hover {
	font-weight: 950;
}

.nav-link.active:hover {
	font-weight: 950;
	text-decoration: underline;
}

.nav-link.active {
	text-decoration: underline;
}

.card {
	color: gray;
}

.dropdown-item {
	color: gray;
}

.dropdown-item:hover {
	color: gray;
}

.dropdown-item.active {
	background-color: #ffaac9;
	color: gray;
}

.dropdown-item:active {
	background-color: #ffaac9;
	color: gray;
}


.carousel-control-prev {
    width: 0;
}

.carousel-control-next {
    width: 0;
}

.form-control {
	background-color: gray;
	color: white;
}

.form-control::placeholder {
	color: white;
}
